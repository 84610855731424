import { Grid } from '@hexa-ui/components';
import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { store } from 'app/store';
import CountryModal from 'components/Country/CountryModal';
import { UserInfoProvider } from 'context/UserInfoContext';
import useSegmentAnalytics from 'hooks/useSegmentAnalytics';
import { GenerateId } from 'jss/src';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Optimizely from 'services/optimizely/optimizely';
import { v4 as uuid } from 'uuid';
import Router from './Router';
import theme, { configureStitches, globalStyles } from './themes';

import 'react-datepicker/dist/react-datepicker.css';

export interface EnvProps {
  optimizelyKey: string;
  payloadValidator: boolean;
  segmentKey: string;
}

function App({ optimizelyKey, payloadValidator, segmentKey }: EnvProps): JSX.Element {
  React.useState(configureStitches());

  globalStyles();

  const userId = uuid();

  useSegmentAnalytics(segmentKey);

  const createGenerateId: GenerateId = (rule) =>
    `data-ingestion--${rule.key}-${uuid().split('-', 1)}`;

  const optimizelyInstance = Optimizely().optimizelyInstance(optimizelyKey);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StylesProvider generateClassName={createGenerateId}>
          <UserInfoProvider>
            <OptimizelyProvider optimizely={optimizelyInstance} user={{ id: userId }}>
              <Grid.Container type="fluid" sidebar>
                <BrowserRouter>
                  <CountryModal />
                  <Router payloadValidator={payloadValidator} />
                </BrowserRouter>
              </Grid.Container>
            </OptimizelyProvider>
          </UserInfoProvider>
        </StylesProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
