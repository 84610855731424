import { Input } from '@hexa-ui/components';
import { Clock } from '@hexa-ui/icons';
import { Box } from '@material-ui/core';
import { format } from 'date-fns';
import { formatMessage } from 'i18n/formatters';
import CalendarPicker from 'pages/Monitoring/components/v2/components/CalendarPicker/CalendarPicker';
import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import './LastUpdatedTab.css';

export type DateFieldsStatus = {
  isStartValid: boolean;
  isEndValid: boolean;
};

export type LastUpdatedTabProps = {
  minStartDate?: Date;
  maxEndDate?: Date;
  minStartTime?: Date;
  maxEndTime?: Date;
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  onChange: (params: DateFieldsStatus) => void;
};

function LastUpdatedTab({
  minStartDate,
  maxEndDate,
  maxEndTime,
  minStartTime,
  onChange,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: Readonly<LastUpdatedTabProps>) {
  const [isStartValid, setIsStartValid] = useState(true);
  const [isEndValid, setIsEndValid] = useState(true);
  const startTimeRef = useRef(null);
  const endTimeRef = useRef(null);

  return (
    <Box className="box-last-update">
      <Box className="last-update-filter">
        <div className="start-end-date">{formatMessage({ id: 'MonitoringPage.START_DATE' })}</div>
        <CalendarPicker
          date={startDate}
          minDateAllowed={minStartDate}
          maxDateAllowed={maxEndDate}
          onRawChange={() => {
            setIsStartValid(false);
            onChange({ isStartValid: false, isEndValid });
          }}
          setStartDate={(date) => {
            setStartDate(date);
            setIsStartValid(true);
            onChange({ isStartValid: true, isEndValid });
          }}
        />
      </Box>
      <Box className="last-update-filter">
        <DatePicker
          name="start-time"
          selected={startDate}
          onChange={(date: Date) => {
            if (date) {
              setStartDate(date);
              onChange({ isStartValid, isEndValid });
            }
          }}
          minTime={minStartTime}
          maxTime={maxEndTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="start time"
          dateFormat="h:mm aa"
          ref={startTimeRef}
          placeholderText="00:00"
          customInput={
            <Input
              data-testid="start-time"
              size="small"
              label={
                (
                  <span className="text-primary">
                    {formatMessage({ id: 'MonitoringPage.START_TIME' })}
                  </span>
                ) as unknown as string
              }
              sufix={
                (
                  <Clock
                    onClick={
                      startTimeRef.current
                        ? (startTimeRef.current as unknown as { onInputClick: () => void })
                            .onInputClick
                        : () => null
                    }
                    style={{
                      cursor: 'pointer',
                      color: '#141414',
                      display: 'flex',
                      alignSelf: 'center',
                    }}
                  />
                ) as unknown as string
              }
              style={{
                fontWeight: 500,
                fontSize: 'var(--fontSizes-3',
              }}
            />
          }
        />
      </Box>
      <Box className="last-update-filter">
        <div className="start-end-date">{formatMessage({ id: 'MonitoringPage.END_DATE' })}</div>
        <CalendarPicker
          date={endDate}
          minDateAllowed={minStartDate}
          maxDateAllowed={maxEndDate}
          onRawChange={() => {
            setIsEndValid(false);
            onChange({ isStartValid, isEndValid: false });
          }}
          setStartDate={(date) => {
            setEndDate(date);
            setIsEndValid(true);
            onChange({ isStartValid, isEndValid: true });
          }}
        />
      </Box>
      <Box className="last-update-filter">
        <DatePicker
          name="end-time"
          selected={endDate}
          onChange={(date: Date) => {
            if (date) {
              setEndDate(date);
              onChange({ isStartValid, isEndValid: true });
            }
          }}
          minTime={minStartTime}
          maxTime={maxEndTime}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeCaption="end time"
          dateFormat="h:mm aa"
          ref={endTimeRef}
          placeholderText="00:00"
          customInput={
            <Input
              data-testid="end-time"
              size="small"
              label={
                (
                  <span className="text-primary">
                    {formatMessage({ id: 'MonitoringPage.END_TIME' })}
                  </span>
                ) as unknown as string
              }
              sufix={
                (
                  <Clock
                    onClick={
                      endTimeRef.current
                        ? (endTimeRef.current as unknown as { onInputClick: () => void })
                            .onInputClick
                        : () => null
                    }
                    style={{
                      cursor: 'pointer',
                      color: '#141414',
                      display: 'flex',
                      alignSelf: 'center',
                    }}
                  />
                ) as unknown as string
              }
              style={{
                fontWeight: 500,
                fontSize: 'var(--fontSizes-3',
              }}
            />
          }
        />
      </Box>
      {(!isStartValid || !isEndValid) && (
        <Box>
          <Box className="out-of-range">{formatMessage({ id: 'MonitoringPage.OUT_OF_RANG' })}</Box>
          <Box>{formatMessage({ id: 'MonitoringPage.CURRENT_START' })}</Box>
          <Box>{format(startDate, 'yyyy/dd/MM HH:mm:ss')}</Box>
          <Box>{formatMessage({ id: 'MonitoringPage.CURRENT_END' })}</Box>
          <Box>{format(endDate, 'yyyy/dd/MM HH:mm:ss')}</Box>
        </Box>
      )}
    </Box>
  );
}

export default LastUpdatedTab;
