import { ThemeOptions } from '@material-ui/core';

export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  gray: '#707372',
  graySecondary: '#141414',
  mediumGray: '#DFDEDE',
  lightGray: '#B9BCC1',
  darkRegular: '#A1A1A1',
  regular: '#D8D8D8',
  textGray: '#444141',
  error: '#E02B27',
  success: '#2BAB3C',
  blueLink: '#4D6EDB',
  blueDrop: '#047AF1',
  background: '#FAFAFA',
  lightBlue: '#F0ECFC',
  brand: '#FFFF00',
  progressBar: {
    blue: '#5379B2',
    success: '#00B8AA',
    failure: '#EB6C64',
  },
  filterChip: {
    main: '#F1EDFC',
    hover: '#D8D5E2',
  },
  payloadCell: {
    backgroundLine: '#DFDEDE',
    feedbackContainer: '#707372',
  },
};

export default {
  primary: {
    main: colors.black,
  },
  secondary: {
    main: colors.gray,
  },
  error: {
    main: colors.error,
  },
  success: {
    main: colors.success,
  },
  text: {
    primary: colors.black,
    secondary: colors.gray,
    hint: colors.gray,
  },
  background: {
    default: colors.background,
    paper: colors.white,
  },
  divider: colors.lightGray,
} as ThemeOptions['palette'];
