/* eslint-disable react-hooks/rules-of-hooks */
import { useAuthenticationService as authenticationService } from 'admin-portal-shared-services';

/**
 * Get the email of the user.
 * @returns user email
 */
const getUserEmail = (): string => {
  const userEmail = authenticationService().getUserEmailB2C();
  return userEmail;
};

const getUserCountry = (): string => {
  const userCountry = authenticationService().getCountryB2C();
  return userCountry;
};

const getUserId = (): string => {
  const userId = authenticationService().getUserId();
  return userId;
};

export { getUserCountry, getUserEmail, getUserId };
