export const MAXIMUM_FILE_SIZE = 51000000;
export const MAXIMUM_CHUNK_SIZE = 1000000;
export const MAXIMUM_PAYLOAD_RESPONSE_SIZE = 2 * 1048000;
export const USER_LOCAL_STORAGE_EXPIRATION_TIME = 7 * 24 * 60 * 60 * 1000; // one week
export const BEES_SYNC_DOCUMENTATION_LINK =
  'https://developer.bees-platform.com/doc/csv-integration';
export const BEES_SYNC_PAYLOAD_VALIDATOR_DOC_LINK =
  'https://developer.bees-platform.com/doc/csv-integration';
export const BEES_SYNC_PROJECT_NAME = 'admin-portal-data-ingestion-mfe';
export const APP_NAME = 'SYNC INGESTION';
export const APP_ID = 'INGESTION';
