import {
  AppHeaderConfigPayload,
  useAppHeader,
  useUserMetadata,
} from 'admin-portal-shared-services';
import UserInfoContext from 'context/UserInfoContext';
import { formatMessage } from 'i18n/formatters';
import { useContext, useEffect, useLayoutEffect } from 'react';
import { setUserLocalData } from 'services/userLocalData/UserLocalDataService';

interface IUseAppHeaderService extends AppHeaderConfigPayload {
  hidePageTitle?: boolean;
}

export const useAppHeaderService = ({ hidePageTitle, ...config }: IUseAppHeaderService = {}) => {
  const [appHeaderConfig, setAppHeaderConfig] = useAppHeader();
  const { setUserInfoContext } = useContext(UserInfoContext);
  const { data: metadata, isLoading: isMetadataLoading } = useUserMetadata();

  const { selectedCountry, selectedVendor, loadingVendors, countryOptions } = appHeaderConfig;

  useEffect(() => {
    setAppHeaderConfig({
      pageTitle: formatMessage({ id: 'WelcomePage.TITLE' }),
      countrySelect: true,
      vendorSelect: true,
      ...config,
    });
  }, [setAppHeaderConfig]);

  useLayoutEffect(() => {
    const title = document.querySelector('header#mfe-content-header') as HTMLElement;
    if (hidePageTitle && title) {
      title.classList.add('hideTitle');
    }
    return () => {
      if (title) title.classList.remove('hideTitle');
    };
  }, []);

  useEffect(() => {
    if (countryOptions.length) {
      setUserLocalData({ countriesAvailable: countryOptions });
    }
  }, [countryOptions]);

  useEffect(() => {
    if (selectedCountry && selectedVendor && !loadingVendors && !isMetadataLoading) {
      setUserInfoContext({
        selectedCountry,
        selectedVendor,
      });

      const vendors = metadata?.vendors || [];
      const vendor = vendors.find((vendorOption) => vendorOption.id === selectedVendor);
      setUserLocalData({
        selectedCountry,
        selectedVendor: {
          id: vendor?.id || '',
          name: vendor?.displayName || '',
          serviceModel: vendor?.serviceModel || '',
          country: vendor?.country || '',
          isManufacturer: vendor?.isManufacturer || false,
        },
      });
    }
  }, [selectedCountry, selectedVendor, loadingVendors, isMetadataLoading]);

  return { appHeaderConfig, setAppHeaderConfig };
};
