import MessageMap from '../i18n.d';

const en: MessageMap = {
  WelcomePage: {
    TITLE: 'Data upload and monitoring',
    WELCOME_TITLE: 'Which page would you like to access?',
    DATA_MANAGEMENT_TEXT_ITEM: 'Download or upload data on Bees app',
    MONITORING_TEXT_ITEM: 'Visualize all the data uploaded and check the errors',
    PAYLOAD_VALIDATOR_TEXT_ITEM: 'Verify your code before you process it',
    LINK_TITLE: 'Home',
  },
  DataManagementPage: {
    TITLE: 'Data Management',
    UPLOAD_DATA: 'Upload Data',
    DOWNLOAD_DATA: 'Download Data',
    HISTORY_TITLE: 'History',
    DOWNLOAD_FILE: 'Download File',
    ENTITY_SELECT: 'Entity',
    DOWNLOAD_TEMPLATE: 'Download Template',
    CHECK_INSTRUCTIONS: 'Check Instructions',
    START_DATE: 'Start date',
    END_DATE: 'End date',
    STATUS_SELECT: 'Status',
    ACCOUNT_ID_SELECT: 'Account ID (optional)',
    ACTION_SELECT: 'Action',
    RESET_MODAL_TITLE: 'Upload new file?',
    RESET_MODAL_CANCEL: 'Cancel',
    RESET_MODAL_CONFIRM: 'Yes, redirect me',
    RESET_MODAL_TEXT: "You'll be redirected to the first step to upload a new file.",
    CONFIRMATION_MODAL_TITLE: 'Upload file?',
    CONFIRMATION_MODAL_SUBTITLE: 'This information will be inserted into BEES.',
    CONFIRMATION_MODAL_BUTTON_TEXT: 'Yes, upload',
    UPLOAD_DATA_TITLE: `Choose the 'entity' and action to upload data`,
    DOWNLOAD_DATA_TITLE: `Choose the 'entity' and other fields to download data`,
    CHOOSE_FILE_TITLE: 'Drop a CSV file here to start uploading',
    CHOOSE_FILE_TITLE_COMPLEMENT: 'or',
    CHOOSE_FILE_BUTTON_TEXT: 'Browse file',
    CHOOSE_FILE_MESSAGE: 'Accepted only .csv files with max size of 50mb',
    CONFIRMATION_MODAL_KEY_FILE: 'File',
    CONFIRMATION_MODAL_KEY_COUNTRY: 'Country',
    CONFIRMATION_MODAL_KEY_VENDORS: 'Vendors',
    CONFIRMATION_MODAL_KEY_ENTITY: 'Entity',
    CONFIRMATION_MODAL_KEY_ACTION: 'Action',
    NO_DATA_FOUND: 'No data found for your search criteria.',
    DOWNLOAD_SUCCESSFUL: 'Download successful!',
    UPLOAD_RESPONSE_SUCCESS: 'File uploaded successfully!',
    BULK_UPLOAD_RESPONSE_SUCCESS_TITLE: 'parent trace IDs generated',
    BULK_UPLOAD_RESPONSE_SUCCESS_MESSAGE: 'Show all',
    UPLOAD_RESPONSE_SUCCESS_MESSAGE: 'Your parent trace ID is: ',
    UPLOAD_GENERIC_TITLE: 'Error in file upload',
    UPLOAD_GENERIC_MESSAGE: 'Review the errors and upload again.',
    UPLOAD_GENERIC_MESSAGE_MAXIMUM_LINES_EXCEEDED:
      'Check bellow the first 100 lines with error. You can see all the lines on the history tab.',
    CONFIRMATION_MODAL_CANCEL_TEXT: 'Cancel',
    SELECT_ENTITY_PLACEHOLDER: 'Select an entity',
    SELECT_ACTION_PLACEHOLDER: 'Select an action',
    SELECT_START_DATE_PLACEHOLDER: 'Select the start date',
    SELECT_END_DATE_PLACEHOLDER: 'Select the end date',
    SELECT_STATUS_PLACEHOLDER: 'Select a status',
    IMPORT_DATA_TAB_DIALOG_STOP_TITLE: 'Your file still processing',
    IMPORT_DATA_TAB_DIALOG_STOP_SUBTITLE: 'Are you sure you want to stop importing it?',
    IMPORT_DATA_TAB_DIALOG_STOP_CONFIRM_BUTTON: 'Yes, stop',
    DOWNLOAD_SUCCESSFUL_BUT_NOT_STARTED:
      'If your download does not start automatically, click on the button beside.',
    TRY_DOWNLOAD_AGAIN: 'Try again',
    STATUS_SELECT_ALL_AVAILABLE: 'All Available',
    STATUS_SELECT_OPTION1: 'Pending',
    STATUS_SELECT_OPTION2: 'Pending Cancellation',
  },
  TraceIdColumnComponent: {
    COPY_TEXT: 'Copy',
    COPIED_TOOLTIP: 'Copied!',
    ERROR_COPYING_TOOLTIP: 'Error copying :(',
  },
  UploadDataTab: {
    DEFAULT_BACK_BUTTON: 'Go Back',
    FIRST_STEP_TITLE: 'Select file',
    FIRST_STEP_DETAIL: 'Define settings and file.',
    FIRST_STEP_NEXT_BUTTON: 'Upload file',
    FIRST_STEP_NEXT_TITLE_MULTIPLE: 'Select vendors',
    FIRST_STEP_NEXT_DETAILS_MULTIPLE: 'Choose who will be impacted.',
    FIRST_STEP_NEXT_BUTTON_MULTIPLE: 'Select vendors',
    LAST_STEP_TITLE: 'Upload file',
    LAST_STEP_DETAIL: `Insert data into BEES.`,
    LAST_STEP_NEXT_BUTTON_SUCCESS: 'Upload a new file',
    LAST_STEP_NEXT_BUTTON_ERROR: 'Start over',
    OF_TEXT: 'of',
    VENDOR: 'Vendor',
    PARENT_TRACE_ID: 'Parent trace ID',
    LINE: 'Line',
    COLUMN_NAME: 'Column Name',
    ERROR_MESSAGE: 'Error Message',
    SELECTED_TEXT: 'selected',
    SELECT_AT_LEAST_ONE_TEXT: 'Select at least one vendor',
    VENDORS_STEP_TITLE: 'Select vendors',
    SELECT_VENDORS_TABLE_NAME: 'Vendor name',
    SELECT_VENDORS_TABLE_ID: 'Vendor ID',
    SELECT_VENDORS_TABLE_MODEL: 'Buss. Model',
    UPLOAD_PROGRESS_TITLE: 'Upload progress',
    UPLOAD_PROGRESS_INTERRUPT_BUTTON: 'Interrupt',
    UPLOAD_PROGRESS_SUCCESS_LABEL_PART_1:
      'File uploaded successfully. However, you must check the processing in the ',
    UPLOAD_PROGRESS_SUCCESS_LABEL_PART_2: 'Monitoring',
    UPLOAD_PROGRESS_SUCCESS_LABEL_PART_3: ' section. It may contain errors.',
    UPLOAD_PROGRESS_ERROR_LABEL: 'Invalid information found',
    UPLOAD_PROGRESS_LABEL: 'Processing file',
    COPY_TEXT: 'Copy',
  },
  UploadMode: {
    TITLE: 'Upload file to',
    SINGLE_VENDOR: 'Single vendor',
    MULTIPLE_VENDORS: 'Multiple vendors',
  },
  ChooseFile: {
    TITLE: 'Select your file',
  },
  TemplateLink: {
    UNFILLED_MESSAGE: 'Select the entity and action to access the template.',
  },
  HistoryTable: {
    STATUS_COLUMN: 'Status',
    STATUS_VALUE_SUCCESS: 'Success',
    STATUS_VALUE_FAILURE: 'Failed',
    DATE_COLUMN: 'Date',
    TRACE_ID_COLUMN: 'Trace ID',
    ERROR_DESCRIPTION_COLUMN: 'Error Description',
    ERROR_DESCRIPTION_VALUE: 'Details',
    TOTAL_ROWS_COLUMN: 'Rows',
    ENTITY_COLUMN: 'Entity',
    ACTION_COLUMN: 'Action',
    USER_COLUMN: 'User',
    EMPTY_TITLE: 'No Uploads',
    EMPTY_MESSAGE: 'Your uploaded files will be displayed here.',
    LINE: 'Line',
    COLUMN_NAME: 'Column Name',
    ERROR_MESSAGE: 'Error Message',
  },
  MonitoringPage: {
    TITLE: 'Monitoring',
    MONITORING_INFO_TEXT: 'Get an overview on how entities are performing.',
    TABLE_COLUMN_TIME: 'Time',
    TABLE_COLUMN_PARENTTRACEID: 'Parent Trace ID',
    TABLE_COLUMN_ENTITY: 'Entity',
    TABLE_COLUMN_ACTION: 'Action',
    TABLE_COLUMN_VERSION: 'Version',
    TABLE_COLUMN_TOTALROWS: 'Total Rows',
    TABLE_COLUMN_ERRORROWS: 'Error Rows',
    TABLE_COLUMN_ERRORROW: 'Error Row',
    TABLE_COLUMN_STATUS: 'Status',
    TABLE_COLUMN_TRACEID: 'Trace ID',
    TABLE_COLUMN_PAYLOAD: 'Payload',
    TABLE_COLUMN_ERRORMESSAGE: 'Error Message',
    PARENT_TRACE_ID_COPY_TEXT: 'Copied',
    AUTOCOMPLETE_SEARCH_PLACEHOLDER: 'Search by Parent Trace ID',
    SEARCH_BUTTON: 'Search',
    FILTERS: 'Filters',
    CLEAR_ALL: 'Clear all',
    VERSION: 'Version',
    V1: 'V1',
    V2: 'V2',
    V3: 'V3',
    PROCESSING: 'Processing',
    STATUS: 'Status',
    PENDING: 'Pending',
    PROCESSING_WITH_FAILURES: 'Processing with failures',
    PROCESSING_CONCLUDED: 'Concluded',
    PROCESSING_FAILED: 'Failed',
    PROCESSING_CONCLUDED_WITH_FAILURES: 'Completed with failures',
    ENTITY_ACCOUNTS_FILTER: 'Accounts',
    ENTITY_FILTER: 'Entity',
    ACTION_FILTER: 'Action',
    FAILURE_STATUS_INDICATOR: 'Failure',
    SUCCESS_TOOLTIP_LABEL: 'Success tooltip label',
    FAILURE_TOOLTIP_LABEL: 'Failure tooltip label',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
    POST: 'POST',
    CONCLUDED: 'Concluded',
    FAILED: 'Failed',
    CONCLUDED_WITH_FAILURES: 'Completed with failures',
    ENTITY: 'Entity',
    ACTION: 'Action',
    VIEWING_PERIOD: 'Viewing Period',
    REFRESH_LABEL: 'Refresh',
    LAST_UPDATE: 'Last Update',
    SUCCESS: 'Success',
    FAILURE: 'Failure',
    UPDATE: 'Update',
    PAYLOAD_VIEW_DETAILS: 'View Payload details in JSON',
    FIVE_MINUTES: 'Last 5 minutes',
    THIRTY_MINUTES: 'Last 30 minutes',
    SIXTY_MINUTES: 'Last 60 minutes',
    THREE_HOURS: 'Last 3 hours',
    SIX_HOURS: 'Last 6 hours',
    TWELVE_HOURS: 'Last 12 hours',
    TWENTY_FOUR_HOURS: 'Last 24 hours',
    TOOLTIP_CONCLUDED: 'Payload validated with success.',
    TOOLTIP_FAILURE: 'Payload cointains wrong information in the field or a malformed payload.',
    TOOLTIP_FAILED: 'Payload cointains wrong information in the field or a malformed payload.',
    TOOLTIP_CONCLUDED_WITH_FAILURES:
      'Payload has been processed however some information contains errors in the messages.',
    TOOLTIP_PROCESSING: 'Payload is under validation.',
    TOOLTIP_PROCESSING_WITH_FAILURES:
      'Payload is under validation and some information contains errors in the messages.',
    PAYLOAD_TOO_BIG: 'The payload is too big to be displayed.',
    DOWNLOAD_BUTTON: 'Download',
    DATE_UPDATE_REPORT_FILTERS: 'Data update report filters',
    SELECT_FILTERS_TO_GENERATE_A_REPORT: 'Select filters to generate a report.',
    APPLY: 'Apply',
    DATA_UPDATE_REPORT: 'Data update report',
    CLEAR_IDS: ' ✕ Clear IDs',
    SHOW_SIMILAR_ID_MATCHES: 'Show similar ID matches',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    SELECTED_IDS: 'Selected IDs:',
    EMPTY_TEXT_TABLE:
      'There are no data updates for the applied filters. Try selecting different ones.',
    REQUIRED_FIELD: 'Required Field',
    END_TIME: 'End Time (Local)',
    START_TIME: 'Start Time (Local)',
    DATE_OUT_OF_RANGE: 'Date out of range',
    NO_RESULTS_AVALIABLE: 'No results available',
  },
  Entities: {
    ACCOUNTS: 'Accounts',
    ACCOUNTS_BASIC: 'Accounts basic',
    ACCOUNTS_CREDITS: 'Accounts credits',
    ACCOUNTS_FINANCIAL: 'Accounts financial',
    BILLING: 'Billing',
    CARE_ASSETS: 'Care assets',
    CATEGORIES: 'Categories',
    CHARGES: 'Charges',
    COMBOS: 'Combos',
    COMBOS_ACCOUNTS: 'Combos accounts',
    COMBOS_CONSUMPTION: 'Combos consumption',
    COMBOS_DELIVERY_CENTERS: 'Combos delivery centers',
    CONSUMER_COUPONS_WALLET: 'Consumer coupons wallet',
    CREDITS: 'Credits',
    DEALS: 'Deals',
    DELIVERY_WINDOWS: 'Delivery windows',
    EMPTIES_MANAGEMENT: 'Empties management',
    EMPTIES_REGISTER: 'Empties register',
    ENFORCEMENTS: 'Enforcements',
    FILES: 'Files',
    FORCE_COINS_WALLET: 'Force coins wallet',
    FORCE_UCC: 'Force UCC',
    FORCE_VARIABLE_COMPENSATION: 'Force variable compensation',
    FORCE_VISITS: 'Force visits',
    GROW_LOCAL_MISSION: 'Grow local mission',
    INVENTORY: 'Inventory',
    INVOICES: 'Invoices',
    ITEMS: 'Items',
    LOYALTY_BUSINESS_REDEMPTION: 'Loyalty business redemption',
    ORDERS: 'Orders',
    PRICES: 'Prices',
    PRODUCT_ASSORTMENT_EXCLUSION: 'Product assortment exclusion',
    PRODUCT_ASSORTMENT_INCLUSION: 'Product assortment inclusion',
    PRODUCT_ASSORTMENT_UNAVAILABLE: 'Product assortment unavailable',
    PROMOTIONS: 'Promotions',
    PROMOTIONS_ACCOUNTS: 'Promotions accounts',
    REGION_COUPONS_WALLET: 'Region coupons wallet',
    FORCE_LOCAL_DATA: 'Force local data',
    ENFORCEMENTS_ADJUSTMENTS: 'Enforcements adjustments',
    INVENTORY_DETAIL: 'Inventory detail',
    ENFORCEMENTS_ENTITY_LISTS: 'Enforcements entity lists',
    REDEEMABLES_SEGMENTATION_AUDIENCE: 'Redeemables segmentation audience',
    REDEEMABLES_SEGMENTATION_CATALOG: 'Redeemables segmentation catalog',
    DELIVERY_DAYS_OF_WEEK: 'Delivery days of week',
    DATA_PORTFOLIO_MODEL: 'Data portfolio model',
  },
  PayloadValidatorPage: {
    TITLE: 'Payload Validator',
    HELP_TEXT: 'Validate requests made through API request.',
    HELP_TEXT_LINK: 'More info',
    PAYLOAD_TITLE: 'Payload',
    RESPONSE_TITLE: 'Response',
    METHOD_SELECT: 'Method',
    SWITCH_RAW: 'Raw',
    SWITCH_FORMATTED: 'Formatted',
    PROCESS_BUTTON: 'Process',
    PAYLOAD_DETAILS: 'Payload Details',
    ENTITY: 'Entity',
    ACTION: 'Action',
    ESCAPE_STRING: 'Escape String',
    GENERATE_BUTTON: 'Generate',
    ERROR_MESSAGE: 'Error Message',
    JSON_FEEDBACK: 'JSON FEEDBACK',
    CLOSE: 'Close',
    COPY_JSON: 'Copy JSON',
    ASSISTED_TAB: 'Assisted',
    UNASSISTED_TAB: 'Unassisted',
  },
  ErrorMessages: {
    GENERIC_ERROR_MESSAGE:
      'We are unable to perform this action at the moment. Please try again later.',
    INCOMPATIBLE_FILE: 'This file cannot be uploaded. Please select another file.',
    INVALID_FILE: 'Only .csv files are allowed.',
    FILE_TOO_LARGE: 'File is larger than 50mb.',
    FILE_TOO_SMALL: 'File is empty.',
    TOO_MANY_FILES: 'Accepts only one file at a time.',
    FAIL_TO_LOAD_ENTITIES: 'Entities could not be loaded for this vendor.',
    FAIL_TO_LOAD_ALL_ENTITIES: 'Entities and Actions could not be loaded.',
    NONE_ENTITITES_AVAILABLE: 'There are no entities available for csv upload to this vendor.',
    NONE_ENTITITES_DOWNLOAD_AVAILABLE:
      'There are no entities available for csv download to this vendor.',
    FAIL_TO_LOAD_COUNTRIES: 'Countries could not be loaded',
    NONE_COUNTRIES_AVAILABLE: "Looks like you don't have access to any country or vendor",
    CONTACT_ADMINISTRATOR: 'Please contact your system administrator.',
    FAIL_TO_LOAD_VENDOR_IDS: "VendorId's could not be loaded for this country.",
    INVALID_METHOD: 'Selected method not valid.',
    DETAILS_INVALID_METHOD:
      'You choose a method that is not implemented yet. Please contact your system administrator for more information.',
    MAXIMUM_FILE_SIZE: 'File size is larger than allowed.',
    IMPORT_CANCELED: 'File import has been canceled.',
    SYSTEM_OFFLINE: 'Looks like you are offline. Your file import will be interrupted.',
    IMPORT_INTERRUPTED: 'File import has been interrupted.',
    IMPORT_INTERRUPTED_DETAILS: 'Please check your internet connection and try again.',
    MALFORMED_JSON: 'Malformed JSON',
    PAYLOAD_NOT_VALID: 'The payload is not in a valid format',
    COUNTRY_MODAL_ERROR_TITLE: 'Registration error',
    COUNTRY_MODAL_ERROR_SUBTITLE:
      'It looks like your registration is not complete.\nContact the responsible for your user and try again later.',
    UPLOAD_STOPPED_BY_USER: 'Upload interrupted by the user',
    UPLOAD_STOPPED_BY_USER_MESSAGE: 'You can continue the upload when you want.',
  },
  SuccessMessages: {
    UPLOAD_SUCCESSFUL: 'All good! Your file has been imported.',
    DOWNLOAD_SUCCESSFUL: 'All good! Your file is ready.',
  },
  RequestMessages: {
    LOADING_HEADER: 'Loading',
    LOADING_DESCRIPTION: 'We are preparing the page for you.',
    NO_RESULTS_HEADER: 'Zzz...',
    NO_RESULTS_DESCRIPTION: 'There are no campaigns to display.',
    ERROR_HEADER: 'Something went wrong',
    ERROR_DESCRIPTION: `Sorry, we're having technical issues. Please try again.`,
  },
  Components: {
    OPTIONAL_LABEL: '(Optional)',
    COUNTRY_MODAL_TITLE: 'Welcome!',
    COUNTRY_MODAL_SUBTITLE:
      'Before starting using Data upload and monitoring, select the country you want to view.',
    COUNTRY_MODAL_CONFIRM_BUTTON: 'Confirm',
    COUNTRY_MODAL_BACK_BUTTON: 'Back',
    COUNTRY_MODAL_CANCEL_BUTTON: 'Cancel',
    COUNTRY_MODAL_SELECT_PLACEHOLDER: 'Select a country',
    COUNTRY_BUTTON_EDIT: 'Edit',
    COUNTRY_TOOLTIP_MESSAGE: 'Please select a country to continue.',
    COUNTRY_GO_BACK: 'Go back to home',
    COUNTRY_INPUT_LABEL: 'Country:',
    COUNTRY_MODAL_INPUT_LABEL: 'Country',
    VENDOR_ID_SELECT_LABEL: 'Vendor: ',
    HELPER_TEXT_QUESTION: 'Need help?',
    HELPER_TEXT_LINK: 'Template and document for guidance.',
    HELPER_DOWNLOAD_TEMPLATE: 'Click here to download the template',
    HELPER_DOWNLOAD_TEXT: 'Click here to download the template. ',
    HELPER_DOWNLOAD_IF_YOU_NEED: 'If you need to, ',
    HELPER_DOWNLOAD_INSTRUCTIONS: 'click here to see the instructions ',
    HELPER_DOWNLOAD_FILL_IT: 'to fill it.',
    HELPER_FALLBACK_DOWNLOAD: 'There is no template available for the selected entity and action.',
    NOT_FOUND_HEADER_TEXT: 'Mmm… Looks like this page is gone',
    NOT_FOUND_SUBHEADER_TEXT: "It looks like this page wasn't found or doesn't exist.",
    NOT_FOUND_HOME_BTN: 'Home',
    MENU_HELP: 'Help',
    MENU_COPYRIGHT: '© 2022, BEES',
  },
  Pagination: {
    ROWS_PER_PAGE: 'Rows per page',
  },
  Countries: {
    AR: 'Argentina',
    BB: 'Barbados',
    BE: 'Belgium',
    BO: 'Bolivia',
    BR: 'Brazil',
    CA: 'Canada',
    CL: 'Chile',
    CO: 'Colombia',
    DE: 'Germany',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ES: 'Spain - Canary Islands',
    GB: 'Great Britain',
    KR: 'South Korea',
    SV: 'El Salvador',
    HN: 'Honduras',
    MX: 'Mexico',
    NL: 'Netherlands',
    PE: 'Peru',
    PA: 'Panama',
    PY: 'Paraguay',
    PT: 'Portugal',
    ZA: 'South Africa',
    TZ: 'Tanzania',
    UG: 'Uganda',
    US: 'United States',
    UY: 'Uruguay',
  },
};

export default en;
