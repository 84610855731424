/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { initialAxios } from 'Api/Api';
import { RootState } from 'app/store';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { allowedToChangePath, unallowedToChangePath } from 'consts/europeClusters';
import { ICountriesList } from 'features/europeCluster/europeCluster';
import { ICountry } from 'interfaces/ICountry';
import { useSelector } from 'react-redux';

export type Countries = ICountry['code'] | 'default' | string;

const retryMap: Map<string, number> = new Map<string, number>();
const maxRetries = 4;

const allowToChangePath = (url: string): boolean =>
  !!(
    allowedToChangePath.find((item) => url.includes(item)) &&
    !unallowedToChangePath.find((item) => url.includes(item))
  );

export const clearRetryMap = (urlToClear: string) => {
  if (retryMap.has(urlToClear)) {
    retryMap.delete(urlToClear);
  }
};

export const getRetryMap = () => retryMap;

export const resolveRequestConfig = (
  config: AxiosRequestConfig,
  shouldChangePath: boolean,
  list: ICountriesList[]
): AxiosRequestConfig => {
  if (shouldChangePath) {
    if (typeof config.url === 'string') {
      const result = allowToChangePath(config.url);
      if (result) {
        const [, path] = config.url.split('/api/');
        const url = new URLSearchParams(config?.url);
        const urlCountry = url.get('country') || url.get('countries');
        const country = config?.headers?.country || urlCountry;
        const baseUrl = list.find((item) => item.country === country)?.path || '/api/';
        return {
          ...config,
          url: `${baseUrl}${path}`,
        };
      }
    }
  }

  return config;
};

export const retryDelayWithBackoff = (originalRequestConfigUrl: string): number => {
  const retryCount = retryMap.get(originalRequestConfigUrl) ?? 1;
  const retryDelay = 2 * retryCount * 1000;
  console.warn('Retry Counter: %d - Retry Delay: %d', retryCount, retryDelay);

  if (retryCount < maxRetries) {
    retryMap.set(originalRequestConfigUrl, retryCount + 1);
    return retryDelay;
  }
  return 0;
};

export function useRequestInterceptor(): void {
  const { shouldChangePath, list } = useSelector((state: RootState) => state.europeCluster);

  initialAxios.interceptors.request.use((config: AxiosRequestConfig) =>
    resolveRequestConfig(config, shouldChangePath, list)
  );

  /* istanbul ignore next */
  initialAxios.interceptors.response.use(
    (response) => {
      clearRetryMap(response.config.url!);
      return response;
    },
    /* istanbul ignore next */
    (error: AxiosError) => {
      const originalRequestConfig = error.config!;
      if (error.response?.status === 503 || error.response?.status === 500) {
        if (
          retryMap.has(originalRequestConfig.url!) &&
          retryMap.get(originalRequestConfig.url!)! >= maxRetries
        ) {
          return Promise.reject(error);
        }
        const retryDelay = retryDelayWithBackoff(originalRequestConfig.url!);
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            initialAxios.request(originalRequestConfig).then(resolve).catch(reject);
          }, retryDelay);
        });
      }
      clearRetryMap(originalRequestConfig.url!);
      return Promise.reject(error);
    }
  );
}
