import { useAnalyticsService, useUserMetadata } from 'admin-portal-shared-services';
import { ISegmentProps, ISegmentRequiredProps, SegmentEventsType } from 'interfaces/ISegment';
import { useEffect, useRef } from 'react';
import { createSegmentEvent } from 'services/segment/segmentEventService';
import { getUserLocalData } from 'services/userLocalData/UserLocalDataService';

export const useSegmentAnalytics = (segmentKey: string): void => {
  const analyticsService = useAnalyticsService();

  useEffect(() => {
    analyticsService.load(segmentKey);
  }, [analyticsService, segmentKey]);
};

export const useSegmentEvent = (): ((
  event: SegmentEventsType,
  args?: Partial<ISegmentProps>
) => void) => {
  const { selectedCountry, selectedVendor } = getUserLocalData();
  const { data: userMetaData } = useUserMetadata();

  const analyticsParamsRef = useRef<ISegmentRequiredProps>();

  const event = (eventName: SegmentEventsType, args?: Partial<ISegmentProps>): void =>
    createSegmentEvent(
      analyticsParamsRef,
      selectedCountry,
      selectedVendor,
      userMetaData
    )(eventName, args);

  return event;
};

export default useSegmentAnalytics;
