import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type MonitoringSlice = {
  retentionDays: number;
};

const initialState: MonitoringSlice = {
  retentionDays: 10,
};

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    setRetentionDays: (state, { payload }: PayloadAction<number>) => {
      state.retentionDays = payload;
    },
  },
});

export default monitoringSlice.reducer;
export const { setRetentionDays } = monitoringSlice.actions;
